@tailwind base;
@tailwind components;
@tailwind utilities;

// // Overwrite Bootstrap's variables here (see _variables.scss in Bootstrap)

// ----- COLORS ------
$blue: rgb(0, 114, 188);
$red: rgb(162, 18, 51);
$yellow: rgb(228, 160, 37);
$green: rgb(0, 148, 68);

$blue-faded: rgb(76, 140, 189);
$red-faded: rgb(201, 90, 92);

$dark: #0f1319;

$gray: #d3d2d2;
$gray-2: #dddddd;
$gray-dark: #a2a2a2;
$gray-darker: #6b6a6a;
$gray-light: #f2f2f2;

// // ----- FONTS -----
$font-size-base: 0.9rem;
$font-family-sans-serif: 'Open Sans', sans-serif !default;

// ----- THEMES ------
$primary: $blue;
$secondary: $yellow;
$success: $green;
$info: $blue-faded;
$warning: $red-faded;
$danger: $red;
$light: $gray-light;
$dark: $dark;

// // ----- PADDING -----
$input-btn-padding-y: 0.2rem;
$input-btn-padding-x: 0.5rem;

$table-cell-padding: 0.3rem !default;
$table-cell-padding-sm: 0.15rem !default;

$card-spacer-y: 0.33rem !default;
$card-spacer-x: 0.75rem !default;

$pagination-padding-y: 0.3rem !default;
$pagination-padding-x: 0.5rem !default;
$pagination-padding-y-sm: 0.1rem !default;
$pagination-padding-x-sm: 0.3rem !default;
$pagination-padding-y-lg: 0.5rem !default;
$pagination-padding-x-lg: 0.75rem !default;
$pagination-line-height: 0.3 !default;

@import './node_modules/bootstrap/scss/bootstrap.scss';

@each $color, $value in $theme-colors {
  .btn-outline-#{$color} {
    background-color: white;
  }
}

html,
body,
.page {
  height: 100%; /* needed for proper layout */
}

body {
  overflow: hidden;
}

@import '@angular/cdk/overlay-prebuilt.css';

.cdk-global-overlay-wrapper {
  overflow: auto;
}
.cdk-dialog-container {
  background: #fff;
  border-radius: 8px;
}

.pms-fieldset {
  @extent .form-group;

  label {
    margin-bottom: 1px;
    font-weight: bold;
    //font-size:@field-label-font-size;
  }

  small {
    color: $red;
  }

  textarea,
  input {
    @extend .form-control;
    @extent .input-sm;
  }
}
