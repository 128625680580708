@table-header-bg: #818284;
@table-summary-bg: #bdbec1;
@field-label-font-size: 14px;
@field-message-font-size: 12px;
@form-bg: #e7e7e8;

body {
  font-family: 'Open Sans';

  .navbar {
    flex-wrap: nowrap;
  }

  button {
    i {
      margin-right: 4px;
    }
  }

  .nc-table {
    /*font-size: 0.7rem;*/
    .total {
      background-color: #cdcdcd;
    }

    & > thead {
      background-color: @table-header-bg;
      color: white;

      & > tr {
        & > td {
          border-left: solid 1px white;
          border-right: solid 1px white;
          padding-top: 5px;
          padding-bottom: 5px;
          vertical-align: middle;

          a {
            color: white;
          }
        }

        & > td:first-child {
          border-left: none;
        }

        & > td:last-child {
          border-right: none;
        }
      }
    }

    & > tbody {
      background-color: white;
      color: black;

      & > tr {
        border-bottom: solid 7px @form-bg;

        &.summary-row {
          background-color: @table-summary-bg;
          padding-top: 5px;
          padding-bottom: 5px;
        }

        & > td {
          border-left: solid 1px @table-header-bg;
          border-right: solid 1px @table-header-bg;
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
        }

        & > td:first-child {
          border-left: none;
        }

        & > td:last-child {
          border-right: none;
        }
      }
    }
  }

  .nc-inner-table {
    thead {
      td {
        vertical-align: middle;
        font-weight: bold;
      }
    }

    tbody {
      td:last-child {
        text-align: right;
      }
    }
  }
}

.modal-title {
  i {
    margin-right: 6px;
  }
}
